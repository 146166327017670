var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "speaking-by-turntable-game-container layout-border" },
    [
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          isLastStep: _vm.isLastStep,
        },
        on: { clickNext: _vm.nextPage },
      }),
      _c(
        "div",
        { staticClass: "game-content" },
        [
          _vm.correctIdLisdt.length
            ? _c("Star", {
                attrs: {
                  totalStarNumber: _vm.correctIdLisdt.length,
                  currentIndex: _vm.starNo,
                },
              })
            : _vm._e(),
          _c("div", { staticClass: "background-img-area" }, [
            _c("img", { attrs: { src: _vm.bgImg, alt: "" } }),
          ]),
          _c("div", { staticClass: "overturn-puzzle-area" }, [
            _c("div", { staticClass: "game-area" }, [
              _c("img", {
                ref: "imgBox",
                staticClass: "puzzle-bg-img",
                attrs: { src: _vm.puzzleBgImg },
                on: { load: _vm.getDomHeight },
              }),
              _c(
                "div",
                {
                  staticClass: "map-area",
                  style: {
                    width: _vm.imgHeight + "px",
                    height: _vm.imgHeight + "px",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "map-box" },
                    _vm._l(_vm.mapList, function (item, index) {
                      return _c(
                        "div",
                        { key: index + "col", staticClass: "col-item" },
                        _vm._l(item, function (ele, indexSon) {
                          return _c("div", {
                            key: indexSon + "row",
                            ref: "S" + ele.index,
                            refInFor: true,
                            staticClass: "row-item",
                            class: {
                              noPointerEvents:
                                _vm.dispearIdList.indexOf(ele.id) > -1,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleOverturn(ele.id)
                              },
                            },
                          })
                        }),
                        0
                      )
                    }),
                    0
                  ),
                  _c(
                    "div",
                    { staticClass: "hanzi-img-area" },
                    [
                      _vm._l(_vm.imgList, function (item, index) {
                        return [
                          _c(
                            "transition",
                            { key: index, attrs: { name: "el-fade-in" } },
                            [
                              _c("img", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.dispearIdList.indexOf(index + 1) ===
                                      -1,
                                    expression:
                                      "dispearIdList.indexOf(index + 1) === -1",
                                  },
                                ],
                                key: index,
                                attrs: { src: item.bgImg, alt: "" },
                              }),
                            ]
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
                ]
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }