<template>
  <div class="speaking-by-turntable-game-container layout-border">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
    ></PageButton>
    <div class="game-content">
      <Star
        :totalStarNumber="correctIdLisdt.length"
        :currentIndex="starNo"
        v-if="correctIdLisdt.length"
      />
      <div class="background-img-area">
        <img :src="bgImg" alt="" />
      </div>

      <div class="overturn-puzzle-area">
        <div class="game-area">
          <img
            ref="imgBox"
            class="puzzle-bg-img"
            :src="puzzleBgImg"
            @load="getDomHeight"
          />
          <div
            class="map-area"
            :style="{ width: imgHeight + 'px', height: imgHeight + 'px' }"
          >
            <div class="map-box">
              <div
                class="col-item"
                v-for="(item, index) in mapList"
                :key="index + 'col'"
              >
                <div
                  class="row-item"
                  v-for="(ele, indexSon) in item"
                  :ref="'S' + ele.index"
                  :key="indexSon + 'row'"
                  :class="{
                    noPointerEvents: dispearIdList.indexOf(ele.id) > -1,
                  }"
                  @click="handleOverturn(ele.id)"
                ></div>
              </div>
            </div>

            <div class="hanzi-img-area">
              <template v-for="(item, index) in imgList">
                <transition name="el-fade-in" :key="index">
                  <img
                    :src="item.bgImg"
                    alt=""
                    :key="index"
                    v-show="dispearIdList.indexOf(index + 1) === -1"
                  />
                </transition>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { startConfetti, playCorrectSound } from "@/utils/tools";
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
import Star from "../Star";
export default {
  name: "OverturnPuzzleGame",
  props: {
    bgImg: {
      type: String,
      require: true,
      default: () => {},
    },
    puzzleBgImg: {
      type: String,
      require: true,
      default: () => {},
    },
    imgList: {
      type: Array,
      require: true,
      default: () => [],
    },
    mapList: {
      type: Array,
      require: true,
      default: () => [],
    },
    correctIdLisdt: {
      type: Array,
      require: false,
      default: () => [],
    },
    notClickIdList: {
      type: Array,
      require: false,
      default: () => [],
    },
  },
  components: {
    PageButton,
    Star,
  },
  watch: {
    clickSocketInfo: {
      handler(value) {
        const data = {
          clickType: 20047,
          data: { value },
          text: "OverturnPuzzleGame点击socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },

  data() {
    return {
      clickIndex: 0,
      isLastStep: false,
      clickSocketInfo: {},
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "look",
            startNumberRange: 0,
            endNumberRange: 100,
          },
        ],
      },
      imgDom: null,
      imgHeight: null,
      starNo: 0,
      isFinishLoad: false,
      dispearIdList: [],
    };
  },
  created() {
    this.dispearIdList = JSON.parse(JSON.stringify(this.notClickIdList));
  },
  mounted() {
    this.$bus.$on("ClickOverturnImg", ({ id }) => {
      this.handleOverturn(id, true);
      console.log(id);
    });
  },
  beforeDestroy() {
    this.$bus.$off("ClickOverturnImg");
  },
  methods: {
    getDomHeight() {
      this.imgDom = this.$refs.imgBox;
      this.imgHeight = this.imgDom.getBoundingClientRect().height;
    },
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    handleOverturn(id, isFromSocket = false) {
      console.log(id);
      if (!isFromSocket) {
        this.clickSocketInfo = {
          id,
          value: Math.random(),
        };
      }
      this.dispearIdList.push(id);
      if (!this.correctIdLisdt.length) {
        playCorrectSound(true, false);
        if (this.dispearIdList.length === this.imgList.length) {
          this.isLastStep = true;
          startConfetti();
          playCorrectSound();
        }
      } else {
        if (this.correctIdLisdt.indexOf(id) > -1) {
          playCorrectSound(true, false);
          this.starNo++;
          if (this.starNo === this.correctIdLisdt.length) {
            this.isLastStep = true;
            startConfetti();
            playCorrectSound();
          }
        } else {
          playCorrectSound(false);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.speaking-by-turntable-game-container {
  .game-content {
    width: 100%;
    height: 100%;
    position: relative;
    .background-img-area {
      width: 100%;
      height: 100%;
      img {
        border-radius: 48px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .puzzle-area {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      .overturn-img-area {
        width: 60%;
        height: 50%;
        position: relative;
        .puzzle-bg-img {
          width: 50%;
        }
        .notShow {
          opacity: 0;
        }
        .img-item {
          position: absolute;
          top: 0;
          left: 0;
          img {
            width: 100%;
          }
        }
      }
    }

    .overturn-puzzle-area {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      .game-area {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        .puzzle-bg-img {
          width: 65%;
          height: 65%;
          object-fit: cover;
          // border-radius: 58px;
          min-width: 400px;

          // border-bottom: 30px solid #cd4c3f;
          // border-bottom-left-radius: 30px;
          // border-bottom-right-radius: 30px;
        }
        .map-area {
          position: absolute;
          margin: auto;
          display: flex;

          .map-box {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            .col-item {
              z-index: 100;
              flex: 1;
              display: flex;
              flex-direction: column;
              .row-item {
                flex: 1;
                // background: greenyellow;
                cursor: pointer;
              }
              .noPointerEvents {
                pointer-events: none;
              }
            }
          }

          .hanzi-img-area {
            width: 100%;
            height: 100%;
            img {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
}
</style>
